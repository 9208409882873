<template>
  <v-card class="dxa_modal">
    <v-card-title>
      <h3 class="dxa_modal_title h3">
        {{ $t("manage_equity_holding") }}
      </h3>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form @submit.stop.prevent="add_equity_holding()" ref="form">
          <v-text-field
            color="primary"
            v-model="equityHolding.Name"
            :label="$t('name')"
            :rules="[required]"
            class="pt-0"
          />
          <v-text-field
            color="primary"
            v-model="equityHolding.RegistryNumber"
            :label="$t('cpf')"
            :rules="[required]"
            class="pt-0"
          />
          <v-text-field
            color="primary"
            v-model="equityHolding.Nationality"
            :label="$t('nationality')"
            :rules="[required]"
            class="pt-0"
          />
          <v-text-field
            color="primary"
            v-model="equityHolding.Capital"
            :label="$t('capital')"
            :rules="[required]"
            type="number"
            @change="equityHolding.Capital = parseFloat(equityHolding.Capital)"
            suffix="%"
            class="pt-0"
          />
          <div class="my-2">
            <span>
              {{ $t("is_politically_exposed") }}
            </span>
            <v-row class="mx-12">
              <v-radio-group center v-model="equityHolding.PoliticallyExposed">
                <v-radio :value="true">
                  <template v-slot:label>
                    <span style="color: var(--dark)">{{ $t("yes") }}</span>
                  </template></v-radio
                >
                <v-radio :value="false">
                  <template v-slot:label>
                    <span style="color: var(--dark)">{{ $t("no") }}</span>
                  </template></v-radio
                >
              </v-radio-group>
            </v-row>
          </div>
          <v-card-actions class="pl-0">
            <v-spacer></v-spacer>
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              type="submit"
              @click="add_equity_holding()"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close')"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "AddEquityHoldingModal",
  props: {
    UserId: String,
    Obj: Object,
  },
  data: () => ({
    apiService: new ApiService(),
    edit: false,
    equityHolding: {
      UserId: null,
      Name: null,
      RegistryNumber: null,
      Nationality: null,
      Capital: null,
      PoliticallyExposed: false,
    },
  }),
  async created() {
    if (this.Obj == null) {
      this.equityHolding.UserId = this.UserId;
    } else {
      this.edit = true;
      this.equityHolding = {
        Id: this.Obj.Id,
        UserId: this.UserId,
        Name: this.Obj.Name,
        RegistryNumber: this.Obj.RegistryNumber,
        Nationality: this.Obj.Nationality,
        Capital: this.Obj.Capital,
        PoliticallyExposed: this.Obj.PoliticallyExposed,
      };
    }
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    add_equity_holding() {
      var result = this.$refs.form.validate();
      if (result) {
        this.$emit("update", this.equityHolding);
      }
    },
  },
};
</script>

<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">{{ $t("name") }}</th>
          <th class="text-center">{{ $t("email") }}</th>
          <th class="text-center" v-if="userType != userTypeEnum.Banker">
            {{ $tc("banker", 1) }}
          </th>
          <th class="text-center">{{ $t("invested") }}</th>
          <th class="text-center">{{ $t("pending_allocations") }}</th>
          <th class="text-center">{{ $t("last_login") }}</th>
          <th class="text-center">
            {{ $t("options") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(user, index) in Users" :key="index">
          <td class="text-center">{{ `${user.name} ${user.lastName}` }}</td>
          <td class="text-center">{{ user.email }}</td>
          <td class="text-center" v-if="userType != userTypeEnum.Banker">
            {{ gs.show_name(user.banker) }}
          </td>
          <td class="text-center">
            <div v-if="user.totalInvested && user.totalInvested.length > 0">
              <span
                class="block"
                v-for="(totalInvested, i) in user.totalInvested"
                :key="i"
                >{{
                  formatCurrency(totalInvested.currency, totalInvested.value)
                }}</span
              >
            </div>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <div v-if="user.totalAllocated && user.totalAllocated.length > 0">
              <span
                class="block"
                v-for="(totalAllocated, i) in user.totalAllocated"
                :key="i"
                >{{
                  formatCurrency(totalAllocated.currency, totalAllocated.value)
                }}</span
              >
            </div>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            {{
              user.lastLoginDate
                ? gs.convert_date(user.lastLoginDate, true)
                : "-"
            }}
          </td>
          <td class="text-center">
            <v-btn small color="secondary" @click="user_info(user)">
              {{ $t("see_more") }}
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="userType == userTypeEnum.Partner"
                  class="px-2 ml-1 secondary"
                  @click="open_block_dialog(user)"
                  min-width="0"
                  v-bind="attrs"
                  v-on="on"
                  small
                  ><v-icon v-if="!user.partnerBlocked">mdi-block-helper</v-icon>
                  <v-icon v-else>mdi-lock-open</v-icon></v-btn
                >
              </template>
              <span>{{ $t("block_user") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-if="block_dialog" v-model="block_dialog">
      <DeleteConfirmationModal
        :obj="block_obj"
        :name="block_obj.name + ' ' + block_obj.lastName"
        :CustomMessage="
          block_obj.partnerBlocked
            ? $tc('block_confirmation', 2)
            : $tc('block_confirmation', 1)
        "
        @close="block_dialog = false"
        @delete="block_user"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>
<script>
import GeneralServices from "@/services/GeneralServices";
import ApiService from "@/services/ApiService";
import { UserTypeEnum } from "@/shared/enums/UserType";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import moment from "moment";
import { formatCurrency } from "@/shared/helpers/currencyHelper";

export default {
  name: "InvestorTable",
  props: {
    Users: Array,
  },
  components: {
    DeleteConfirmationModal,
  },
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    userType: null,
    block_obj: null,
    block_dialog: false,
    userTypeEnum: UserTypeEnum,
    formatCurrency,
  }),

  async created() {
    this.userType = JSON.parse(localStorage.getItem("user")).type;
  },
  computed: {},
  methods: {
    open_block_dialog: function (user) {
      this.block_dialog = true;
      this.block_obj = user;
    },
    user_info(user) {
      this.$router.push(`/investors/info/${user.id}`);
    },
    block_user: function (user) {
      this.apiService
        .putRequest("partner/block-user/" + user.id)
        .then((resp) => {
          user.partnerBlocked = resp.blocked;
          this.block_dialog = false;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style scoped>
.block {
  display: block;
}
</style>

<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
      class="mt-5"
    >
      <v-row>
        <v-col cols="12" data-test="adduser:namebox">
          <v-text-field
            :readonly="ReadOnly"
            :label="$t('name')"
            :rules="[required]"
            v-model="user.Name"
            data-test="Admin:Basic:FirstName"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :readonly="ReadOnly"
            :label="$t('last_name')"
            :rules="[required]"
            v-model="user.LastName"
            data-test="Admin:Basic:LastName"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :readonly="ReadOnly"
            @keydown.space.prevent
            :label="$t('email')"
            :rules="[required]"
            v-model="user.Email"
            data-test="Admin:Basic:UserEmail"
          />
        </v-col>
        <v-col v-if="!edit" cols="12">
          <v-select
            :readonly="ReadOnly"
            v-model="user.Type"
            color="primary"
            item-color="primary"
            item-value="Index"
            item-text="Name"
            :disabled="PartnerSelected != null || UserToAddType != null"
            :items="user_type_options"
            :label="$t('user_type')"
            @change="handleChangeUserType"
            data-test="Admin:Basic:UserType"
          ></v-select>
        </v-col>
        <!-- se for manager pergunta se é partner. se for partner pergunta se é manager -->
        <v-col cols="12">
          <div>
            <!-- manager -->
            <v-checkbox
              v-if="
                user.Type === userTypeEnum.Partner && envManagerUser === 'TRUE'
              "
              v-model="userIsManagerAndPartner"
              class="field"
              :disabled="disabledCheckUserIsManagerAndPartner"
              color="primary"
              outlined
              dense
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{
                  $t("user_is_manager")
                }}</span>
              </template>
            </v-checkbox>
          </div>
        </v-col>
        <v-col v-if="user.Type == userTypeEnum.Investor" cols="12">
          <v-combobox
            data-test="Admin:Basic:UserNationality"
            :readonly="ReadOnly"
            :items="countries"
            item-text="name"
            return-object
            @change="nationality_code_changed"
            :rules="[required]"
            v-model="nationalitySelected"
            :label="$t('nationality')"
          >
            <template slot="selection" slot-scope="data">
              <!-- HTML that describe how select should render selected items -->
              <img
                :src="data.item.flag"
                style="width: 20px; height: auto"
                alt=""
              />
              <!-- HTML that describe how select should render selected items -->
              <span class="mx-2">{{ data.item.name }}</span>
            </template>
            <template slot="item" slot-scope="data">
              <img
                :src="data.item.flag"
                style="width: 20px; height: auto"
                alt=""
              />
              <span class="mx-2">{{ data.item.name }}</span>
            </template></v-combobox
          >
        </v-col>
        <v-col v-if="user.Type == userTypeEnum.Investor" cols="12">
          <v-select
            :readonly="ReadOnly"
            v-model="currencyPreference"
            :rules="[selectRequired]"
            :items="currencyOptions"
            item-text="text"
            item-value="value"
            :label="$t('currency')"
            data-test="Admin:Basic:CurrencyPreference"
          ></v-select>
        </v-col>
        <v-col cols="12" v-if="user.Type == userTypeEnum.Investor">
          <p class="mx-auto" style="color: var(--dark)">
            {{ $t("account_type") }}
          </p>
          <v-radio-group
            center
            v-model="user.JuridicalType"
            :readonly="ReadOnly"
          >
            <v-radio :value="0" data-test="Admin:Basic:JuricalTypePhysical">
              <template v-slot:label>
                <span style="color: var(--dark)">{{
                  $t("fisical_person")
                }}</span>
              </template></v-radio
            >
            <v-radio :value="1" data-test="Admin:Basic:JuricalTypeJuridical">
              <template v-slot:label>
                <span style="color: var(--dark)">{{ $t("legal_person") }}</span>
              </template></v-radio
            >
          </v-radio-group>
        </v-col>
        <v-col cols="12" v-if="user.Type == userTypeEnum.Investor">
          <v-text-field
            :readonly="ReadOnly"
            v-if="user.Nationality == 'Brazil'"
            @keydown.space.prevent
            dense
            v-mask="
              user.JuridicalType == JuridicalTypeEnum.NaturalPerson
                ? '###.###.###-##'
                : '##.###.###/####-##'
            "
            type="tel"
            :rules="[required, social_number_valid]"
            v-model="user.SocialNumber"
            :label="
              user.JuridicalType == JuridicalTypeEnum.NaturalPerson
                ? $t('cpf')
                : $t('company_id')
            "
            data-test="Admin:Basic:UserSocialNumber"
          />
          <v-text-field
            v-else
            :readonly="ReadOnly"
            @keydown.space.prevent
            dense
            type="tel"
            :rules="[required]"
            v-model="user.SocialNumber"
            :label="
              user.JuridicalType == JuridicalTypeEnum.NaturalPerson
                ? $t('passport')
                : $t('company_id')
            "
          />
        </v-col>
        <!-- manager user -->
        <v-col
          cols="12"
          v-if="
            user.Type === userTypeEnum.ManagerUser || userIsManagerAndPartner
          "
        >
          <v-text-field
            v-if="user.Type === userTypeEnum.ManagerUser"
            :readonly="ReadOnly"
            @keydown.space.prevent
            type="tel"
            v-model="user.CelPhone"
            :label="$t('celphone')"
            data-test="Admin:Basic:PhoneInputManager"
          />

          <!-- manager company -->
          <v-autocomplete
            :readonly="ReadOnly"
            v-model="user.ManagerPartnerId"
            @keyup="managerPartnerChanged"
            :rules="[required]"
            :items="managerPartnerList"
            item-text="name"
            item-value="id"
            :label="$t('manager_company')"
            :disabled="disabledManagerPartner"
            data-test="Admin:Basic:CompanyInputManager"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{
                    managerPartnerFallback
                      ? $t("enter_manager_company")
                      : $t("profession_not_found")
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          v-if="
            user.Type == userTypeEnum.Banker ||
            user.Type == userTypeEnum.Partner
          "
          cols="12"
        >
          <v-text-field
            :readonly="ReadOnly"
            @keydown.space.prevent
            type="tel"
            v-model="user.CelPhone"
            :label="$t('celphone')"
            data-test="Basic:PhoneInput"
          />
        </v-col>
        <v-col v-if="user.Type == userTypeEnum.Partner" cols="12">
          <div class="add-company-partner-container">
            <v-autocomplete
              :readonly="ReadOnly"
              v-model="user.PartnerB2bId"
              @change="partnerB2bChanged"
              :rules="[required]"
              :items="partnersB2bList"
              item-text="name"
              item-value="id"
              :label="$tc('company_partner', 1)"
              :disabled="disabledPartnerB2bOptions"
              data-test="Basic:PartnerB2BAutoComplete"
            ></v-autocomplete>
            <button
              v-if="!disabledPartnerB2bOptions"
              @click="() => (dialogAddPartnerB2bCompany = true)"
              type="button"
              class="add-company-partner-button"
            >
              <font-awesome-icon
                icon="fa-solid fa-plus"
                class="add-company-partner-container-icon"
              />
            </button>
          </div>
        </v-col>
        <v-col cols="12" v-if="user.Type === userTypeEnum.Banker">
          <v-text-field
            :readonly="ReadOnly"
            @keydown.space.prevent
            dense
            v-mask="'###.###.###-##'"
            type="tel"
            :rules="[required, social_number_valid]"
            v-model="user.SocialNumber"
            :label="$t('cpf')"
            data-test="Admin:Basic:UserBankerSocialNumber"
          />
        </v-col>

        <v-col v-if="user.Type === userTypeEnum.Admin" cols="12">
          <v-select
            :readonly="ReadOnly"
            v-model="user.AdminType"
            :items="adminTypes"
            item-text="text"
            :label="$t('adminType')"
          ></v-select>
        </v-col>
        <v-col v-if="!edit" cols="12" md="6" data-test="adduser:passbox">
          <v-text-field
            :readonly="ReadOnly"
            @keydown.space.prevent
            @keyup="handleChangePassword"
            :label="$t('password')"
            v-model="user.UserPassword"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[
              required,
              min_valid,
              uppercase_required,
              lowercase_required,
              number_required,
              special_required,
              accent_valid,
              passwordMinCharacter,
            ]"
            :type="show1 ? 'text' : 'password'"
            counter
            data-test="Admin:Basic:UserPassword"
            @click:append="show1 = !show1"
          />
        </v-col>
        <v-col v-if="!edit" cols="12" md="6" data-test="adduser:repassbox">
          <v-text-field
            :readonly="ReadOnly"
            v-model="rePassword"
            :label="$t('re_password')"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[required, passwordsMatch]"
            :type="show2 ? 'text' : 'password'"
            data-test="Admin:Basic:UserConfirmPassword"
            @click:append="show2 = !show2"
          />
        </v-col>
        <v-col v-if="!edit" cols="12">
          <ValidateStrengthPassword
            :userPassword="passwordToValidateStrength"
          />
        </v-col>
        <v-col v-if="user.Type == userTypeEnum.Investor" cols="12">
          <v-select
            :readonly="ReadOnly"
            v-model="selected_nda"
            :rules="[selectRequired]"
            :items="eSimNao"
            item-text="text"
            item-value="value"
            :label="$t('signed_nda')"
            data-test="Admin:Basic:SignedNda"
          ></v-select>
        </v-col>
        <v-col v-if="user.Type == userTypeEnum.Investor" cols="12">
          <v-select
            data-test="Admin:Basic:SignedContract"
            :readonly="ReadOnly"
            v-model="selected_contract"
            :rules="[selectRequired]"
            :items="eSimNao"
            item-text="text"
            item-value="value"
            :label="$t('signed_contract')"
          ></v-select>
        </v-col>

        <v-col
          v-if="
            (user.Type == userTypeEnum.Banker && !edit) ||
            user.Type == userTypeEnum.Investor
          "
          cols="12"
        >
          <v-autocomplete
            :readonly="ReadOnly"
            v-model="user.PartnerB2bId"
            @change="partnerB2bChanged"
            :rules="[required]"
            :items="partnersB2bList"
            item-text="name"
            item-value="id"
            :label="$tc('partner', 1)"
            data-test="Admin:Basic:UserPartner"
          ></v-autocomplete>
        </v-col>
        <v-col
          v-if="user.Type == userTypeEnum.Investor && user.PartnerB2bId"
          cols="12"
        >
          <v-autocomplete
            :readonly="ReadOnly"
            v-model="user.BankerId"
            :no-data-text="$t('no_banker')"
            :items="bankersFiltered"
            item-text="Email"
            item-value="Id"
            :label="$tc('banker', 1)"
            data-test="Admin:Basic:Banker"
          ></v-autocomplete>
        </v-col>
        <v-col v-if="user.Type == userTypeEnum.Investor" cols="12">
          <p class="mb-0">{{ $t("is_alpha") }}</p>
          <v-radio-group :readonly="ReadOnly" v-model="user.IsAlpha" row>
            <v-radio
              data-test="Admin:Basic:AlphaUserTrue"
              :label="$t(`yes`)"
              :value="true"
            ></v-radio>
            <v-radio :label="$t(`no`)" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="user.Type == userTypeEnum.Investor" cols="12">
          <p class="mb-0">{{ $t("profile_is_done") }}</p>
          <v-radio-group :readonly="ReadOnly" v-model="user.ProfileDone" row>
            <v-radio
              data-test="Admin:Basic:ProfileDone"
              :label="$t(`yes`)"
              :value="true"
            ></v-radio>
            <v-radio :label="$t(`no`)" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="user.Type == userTypeEnum.Investor" cols="12">
          <p class="mb-0">{{ $t("user_created_use_terms") }}</p>
          <v-radio-group
            :readonly="ReadOnly"
            v-model="user.UseTerms"
            row
            :disabled="disabledUseTerms"
          >
            <v-radio :label="$t(`yes`)" :value="true"></v-radio>
            <v-radio :label="$t(`no`)" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-expansion-panels v-if="user.Type == userTypeEnum.Investor && edit">
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("contact_profile") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="user.Type == userTypeEnum.Investor">
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="date"
                  v-mask="'##/##/####'"
                  hint="dd/mm/yyyy"
                  :label="
                    user.JuridicalType == JuridicalTypeEnum.NaturalPerson
                      ? $t('birth_date')
                      : $t('establishment_date')
                  "
                  prepend-inner-icon="mdi-calendar"
                  :rules="[validDate, min18Years]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  @keydown.space.prevent
                  :rules="[required]"
                  type="tel"
                  v-model="user.CelPhone"
                  :label="$t('celphone')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  @keydown.space.prevent
                  v-model="user.HomePhone"
                  :label="$t('home_phone')"
                />
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.Address"
                  :label="$t('address')"
                  class="purple-input"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  :readonly="ReadOnly"
                  :label="$t('number')"
                  class="purple-input"
                  v-model="user.AddressNumber"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  :readonly="ReadOnly"
                  :label="$t('complement')"
                  class="purple-input"
                  v-model="user.AddressComplement"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  class="purple-input"
                  :label="$t('state')"
                  v-model="user.State"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  :label="$t('neighborhood')"
                  class="purple-input"
                  v-model="user.Neighborhood"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.Country"
                  :label="$t('country')"
                  class="purple-input"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.City"
                  :label="$t('city')"
                  class="purple-input"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.PostCode"
                  class="purple-input"
                  :label="$t('post_code')"
                />
              </v-col>
              <v-col cols="12">
                <p class="mx-auto" style="color: var(--dark)">
                  {{ $t("account_type") }}
                </p>
                <v-radio-group
                  center
                  v-model="user.JuridicalType"
                  :readonly="ReadOnly"
                >
                  <v-radio :value="JuridicalTypeEnum.NaturalPerson">
                    <template v-slot:label>
                      <span style="color: var(--dark)">{{
                        $t("fisical_person")
                      }}</span>
                    </template></v-radio
                  >
                  <v-radio :value="JuridicalTypeEnum.LegalPerson">
                    <template v-slot:label>
                      <span style="color: var(--dark)">{{
                        $t("legal_person")
                      }}</span>
                    </template></v-radio
                  >
                </v-radio-group>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="user.JuridicalType == JuridicalTypeEnum.NaturalPerson"
        >
          <v-expansion-panel-header>
            {{ $t("patrimonial_infos") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="user.Type == userTypeEnum.Investor">
              <v-text-field
                :label="$t('total_assets')"
                v-model="user.TotalAssets"
                v-money="money"
                class="purple-input"
              />
              <v-col cols="12">
                <!-- <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.TotalAssets"
                  type="text"
                  prefix="R$"
                  class="purple-input"
                  @click="handleFormatToNumber"
                  @change="handleFormatToCurrency"
                  @keypress="handlePermissionNumber"
                  :label="$t('total_assets')"
                /> -->
              </v-col>
              <v-col cols="12">
                <span style="color: var(--dark)">
                  {{ $t("assets_origins") }}
                </span>
                <div class="mt-4 mx-12">
                  <v-row align="center">
                    <v-col
                      v-for="(a, i) in asset_options"
                      :key="i"
                      cols="12"
                      md="6"
                      class="my-0 py-0"
                    >
                      <v-checkbox
                        class="my-0 py-0"
                        v-model="a.Marked"
                        :readonly="ReadOnly"
                      >
                        <template v-slot:label>
                          <span style="color: var(--dark)">{{ a.Text }}</span>
                        </template></v-checkbox
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="user.JuridicalType == JuridicalTypeEnum.NaturalPerson"
        >
          <v-expansion-panel-header>
            {{ $t("personal_docs") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="user.Type == userTypeEnum.Investor">
              <v-col cols="12">
                <span>
                  {{ $t("document_type") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="user.DocumentType"
                    :readonly="ReadOnly"
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("rg") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("cnh") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("passport")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.DocumentNumber"
                  class="purple-input"
                  :label="$t('document_number')"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.Issuer"
                  class="purple-input"
                  :label="$t('issuing_body')"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.IssuerState"
                  class="purple-input"
                  :label="$t('state')"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  ref="menuDateIssuer"
                  v-model="menuDateIssuer"
                  :readonly="ReadOnly"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateIssuer"
                      :label="$t('expedition_date')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    class="mx-auto"
                    v-model="dateIssuer"
                    @change="
                      $refs.menuDateIssuer.save(dateIssuer);
                      menuDateIssuer = false;
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.MotherName"
                  class="purple-input"
                  :label="$t('mother_name')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.FatherName"
                  class="purple-input"
                  :label="$t('father_name')"
                />
              </v-col>
              <v-col cols="12">
                <span>
                  {{ $t("legal_representative_question") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="user.HasLegalRepresentative"
                    :readonly="ReadOnly"
                  >
                    <v-radio :value="true">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("yes") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="false">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("no") }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content> </v-expansion-panel
        ><v-expansion-panel
          v-if="user.JuridicalType == JuridicalTypeEnum.LegalPerson"
        >
          <v-expansion-panel-header>
            {{ $t("juridical_info") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="user.Type == userTypeEnum.Investor">
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.CompanyName"
                  class="purple-input"
                  :label="$t('company_name')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.StateRegistration"
                  class="purple-input"
                  :label="$t('state_registration')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.MainEconomicActivity"
                  class="purple-input"
                  :label="$t('main_economic_activity')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.TaxAddress"
                  class="purple-input"
                  :label="$t('tax_address')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.CNAE"
                  class="purple-input"
                  :label="$t('cnae')"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          on-panel
          v-if="
            user.JuridicalType == JuridicalTypeEnum.LegalPerson &&
            user.HasLegalRepresentative
          "
        >
          <v-expansion-panel-header>
            {{ $t("legal_representative_header") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.LegalRepresentative.Name"
                  class="purple-input"
                  :label="$t('name')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.LegalRepresentative.SocialNumber"
                  class="purple-input"
                  :label="$t('cpf')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <span>
                  {{ $t("link_type") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    :readonly="ReadOnly"
                    v-model="user.LegalRepresentative.LinkType"
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("legal_representative")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("attorney")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row> </v-col
              ><v-col cols="12" md="4">
                <span>
                  {{ $t("marital_status") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    :readonly="ReadOnly"
                    v-model="user.LegalRepresentative.MaritalStatus"
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("single")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("married")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("widower")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="3">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("divorced")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.LegalRepresentative.Nationality"
                  class="purple-input"
                  :label="$t('nationality')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.LegalRepresentative.Profession"
                  class="purple-input"
                  :label="$t('profession')"
                />
              </v-col>

              <v-col cols="12" md="3">
                <span>
                  {{ $t("document_type") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    :readonly="ReadOnly"
                    v-model="user.LegalRepresentative.DocumentType"
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("rg") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("cnh") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("passport")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.LegalRepresentative.DocumentNumber"
                  class="purple-input"
                  :label="$t('document_number')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.LegalRepresentative.Issuer"
                  class="purple-input"
                  :label="$t('issuing_body')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.LegalRepresentative.IssuerState"
                  class="purple-input"
                  :label="$t('state')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.LegalRepresentative.IssuerState"
                  class="purple-input"
                  :label="$t('state')"
                /> </v-col
              ><v-col cols="12" md="3">
                <v-menu
                  :readonly="ReadOnly"
                  ref="menuDateLegalRepresentative"
                  v-model="menuDateLegalRepresentative"
                  :close-on-content-click="false"
                  :return-value.sync="dateLegalRepresentative"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateLegalRepresentative"
                      :label="$t('expedition_date')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    class="mx-auto"
                    v-model="dateLegalRepresentative"
                    @change="
                      $refs.menuDateLegalRepresentative.save(
                        dateLegalRepresentative
                      );
                      menuDateLegalRepresentative = false;
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <span>
                  {{ $t("non_resident_investor_question") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="user.HasNonResidentInvestor"
                    :readonly="ReadOnly"
                  >
                    <v-radio :value="false">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("no") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="true">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("yes") }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="
            user.JuridicalType == JuridicalTypeEnum.LegalPerson &&
            user.HasNonResidentInvestor
          "
        >
          <v-expansion-panel-header>
            {{ $t("non_resident_investor") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.NonResidentInvestor.RDE"
                  class="purple-input"
                  :label="$t('rde')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.NonResidentInvestor.CountryLegalRepresentative"
                  class="purple-input"
                  :label="$t('country_representative')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="
                    user.NonResidentInvestor
                      .CountryLegalRepresentativeSocialNumber
                  "
                  class="purple-input"
                  :label="$t('cpf')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.NonResidentInvestor.TaxRepresentative"
                  class="purple-input"
                  :label="$t('tax_representative')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="
                    user.NonResidentInvestor.TaxRepresentativeSocialNumber
                  "
                  class="purple-input"
                  :label="$t('cpf')"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="user.JuridicalType == JuridicalTypeEnum.LegalPerson"
        >
          <v-expansion-panel-header>
            {{ $t("stock_position") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn
              color="primary"
              class="mt-8 mr-9"
              small
              rounded
              @click="open_stock_position_dialog(null)"
              absolute
              fab
              top
              right
              v-if="!ReadOnly"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.NetWorth"
                  type="number"
                  prefix="R$"
                  @change="user.NetWorth = parseFloat(user.NetWorth)"
                  class="purple-input"
                  :label="$t('net_worth')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.AverageMonthlyRevenue"
                  type="number"
                  prefix="R$"
                  @change="
                    user.AverageMonthlyRevenue = parseFloat(
                      user.AverageMonthlyRevenue
                    )
                  "
                  class="purple-input"
                  :label="$t('average_monthly_revenue')"
                />
              </v-col>
            </v-row>
            <v-simple-table style="width: 100%">
              <thead>
                <tr>
                  <th class="text-center">{{ $t("name") }}</th>
                  <th class="text-center">{{ $t("cpf") }}</th>
                  <th class="text-center">{{ $t("nationality") }}</th>
                  <th class="text-center">{{ $t("capital") }}</th>
                  <th class="text-center" v-if="!ReadOnly">
                    {{ $t("options") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(sp, index) in user.StockPositions" :key="index">
                  <td class="text-center">{{ sp.Name }}</td>
                  <td class="text-center">{{ sp.RegistryNumber }}</td>
                  <td class="text-center">{{ sp.Nationality }}</td>
                  <td class="text-center">{{ sp.Capital }}%</td>
                  <td class="text-center" v-if="!ReadOnly">
                    <v-btn
                      class="px-2 ml-1 secondary"
                      @click="open_stock_position_dialog(sp)"
                      min-width="0"
                      small
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      class="px-2 ml-1"
                      @click="open_delete_dialog_stock_position(sp)"
                      color="red"
                      min-width="0"
                      small
                    >
                      <v-icon small>mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="user.JuridicalType == JuridicalTypeEnum.LegalPerson"
        >
          <v-expansion-panel-header>
            {{ $t("equity_holding") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-btn
                color="primary"
                class="mt-8 mr-9"
                small
                rounded
                @click="open_equity_holding_dialog(null)"
                absolute
                fab
                top
                right
                v-if="!ReadOnly"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
              <v-simple-table style="width: 100%">
                <thead>
                  <tr>
                    <th class="text-center">{{ $t("name") }}</th>
                    <th class="text-center">{{ $t("cpf") }}</th>
                    <th class="text-center">{{ $t("nationality") }}</th>
                    <th class="text-center">{{ $t("capital") }}</th>
                    <th class="text-center">
                      {{ $t("is_politically_exposed") }}
                    </th>
                    <th v-if="!ReadOnly" class="text-center">
                      {{ $t("options") }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(eh, index) in user.EquityHoldings" :key="index">
                    <td class="text-center">{{ eh.Name }}</td>
                    <td class="text-center">{{ eh.RegistryNumber }}</td>
                    <td class="text-center">{{ eh.Nationality }}</td>
                    <td class="text-center">{{ eh.Capital }}%</td>
                    <td class="text-center">
                      {{ eh.PoliticallyExposed ? $t("yes") : $t("no") }}
                    </td>
                    <td class="text-center" v-if="!ReadOnly">
                      <v-btn
                        class="px-2 ml-1 secondary"
                        @click="open_equity_holding_dialog(eh)"
                        min-width="0"
                        small
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        class="px-2 ml-1"
                        @click="open_delete_dialog_equity_holding(eh)"
                        color="red"
                        min-width="0"
                        small
                      >
                        <v-icon small>mdi-trash-can</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            v-if="user.JuridicalType == JuridicalTypeEnum.NaturalPerson"
          >
            {{ $t("professional_info") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="user.Type == userTypeEnum.Investor">
              <v-col cols="12" md="4">
                <v-autocomplete
                  :readonly="ReadOnly"
                  :filter="filterObject"
                  v-model="user.ProfessionId"
                  :items="profession_options"
                  :item-text="translateProfessionOptions"
                  item-value="id"
                  :rules="[required]"
                  :label="$t('your_profession')"
                  class="field"
                  @keyup="getProfession"
                  id="profession_input"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{
                          profession_text_follback
                            ? $t("write_your_profession")
                            : $t("profession_not_found")
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.ProfessionalOccupation"
                  class="purple-input"
                  :label="$t('professional_occupation')"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.Employer"
                  class="purple-input"
                  :label="$t('employer')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.EmployerNumber"
                  class="purple-input"
                  :label="$t('company_id')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.EmployerPhone"
                  class="purple-input"
                  :label="$t('phone')"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.EmployerAddress"
                  class="purple-input"
                  :label="$t('address')"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  :readonly="ReadOnly"
                  v-model="user.MonthlyIncome"
                  type="number"
                  class="purple-input"
                  :label="$t('monthly_income')"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="user.JuridicalType == JuridicalTypeEnum.NaturalPerson"
        >
          <v-expansion-panel-header>
            {{ $t("statements") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row v-if="user.Type == userTypeEnum.Investor">
              <v-col cols="12" md="3">
                <span>
                  {{ $t("marital_status") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="user.MaritalStatus"
                    :readonly="ReadOnly"
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("single")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("married")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("widower")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="3">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("divorced")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  {{ $t("sex") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group center v-model="user.Sex" :readonly="ReadOnly">
                    <v-radio :value="0">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("woman")
                        }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="1">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("man") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="2">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{
                          $t("other")
                        }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  {{ $t("is_politically_exposed") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="user.PoliticallyExposed"
                    :readonly="ReadOnly"
                  >
                    <v-radio :value="true">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("yes") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="false">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("no") }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <span>
                  {{ $t("is_us_person") }}
                </span>
                <v-row class="my-4 mx-12">
                  <v-radio-group
                    center
                    v-model="user.USPerson"
                    :readonly="ReadOnly"
                  >
                    <v-radio :value="true">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("yes") }}</span>
                      </template></v-radio
                    >
                    <v-radio :value="false">
                      <template v-slot:label>
                        <span style="color: var(--dark)">{{ $t("no") }}</span>
                      </template></v-radio
                    >
                  </v-radio-group>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header v-if="user.IsAlpha">
            {{ $tc("alpha_investor", 1) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="user.IsAlpha"
            ><v-row>
              <v-col cols="12" md="6" class="pb-0">
                <span>{{ $t("invest_value") }}</span>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <span>{{ $t("selected_portfolio") }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pt-0">
                <money
                  class="register-text-field"
                  :class="{
                    'invalid-field':
                      !user.InvestValue ||
                      user.InvestValue < portfolioSelected.MinimumValue,
                  }"
                  prefix="R$"
                  thousand="."
                  thousands="."
                  decimal=","
                  v-model="user.InvestValue"
                ></money>
                <p
                  style="color: red; font-size: 10px"
                  class="mb-4 mt-n4 ml-2"
                  v-if="
                    !portfolioLoading &&
                    user.InvestValue < portfolioSelected.MinimumValue
                  "
                >
                  {{
                    $t("alpha_register_minimum", {
                      value: portfolioSelected.MinimumValueLabel,
                    })
                  }}
                </p>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <v-select
                  :items="portfolioOptions"
                  :loading="portfolioLoading"
                  item-text="number"
                  color="black"
                  return-object
                  outlined
                  dense
                  v-model="portfolioSelected"
                  :rules="[required]"
                  readonly
                >
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('alpha_register_date')"
                  color="black"
                  dense
                  outlined
                  v-model="alpha_register_date"
                  :rules="[vs.validDate(alpha_register_date)]"
                  v-mask="['##/##/####']"
                  prepend-inner-icon="mdi-calendar"
                  @blur="
                    user.AlphaRegisterDate = gs.parseDate(alpha_register_date)
                  "
                />
              </v-col>
              <v-col cols="12">
                <label>{{ $t("obs") }}</label>
                <v-textarea
                  dense
                  outlined
                  v-model="user.AlphaInvestorAdmNotes"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions class="pl-0 dxa_modal_actions" v-if="!ReadOnly">
        <v-btn
          class="dxa_modal_btnSuccess"
          color="primary"
          min-width="100"
          :loading="loading"
          type="submit"
          data-test="Admin:Basic:SaveUser"
          >{{ $t("save") }}
        </v-btn>
        <v-btn
          class="dxa_modal_btnError"
          color="secondary"
          min-width="100"
          @click="$router.go(-1)"
          >{{ $t("go_back") }}</v-btn
        >
      </v-card-actions>
    </v-form>

    <!-- dialog to add partner company -->
    <v-dialog
      v-model="dialogAddPartnerB2bCompany"
      v-if="dialogAddPartnerB2bCompany"
    >
      <DialogAddOrEditPartnerB2b
        :partnerB2bContent="editPartnerB2bContent"
        @closeDialog="handleCloseDialog"
        @saved="handleSavedPartnerB2b"
      />
    </v-dialog>
    <v-dialog v-model="dialogEquityHolding" v-if="dialogEquityHolding">
      <AddEquityHolding
        :Obj="equityHolding"
        :UserId="user.Id"
        @update="update_equity_holding"
        @close="dialogEquityHolding = false"
      ></AddEquityHolding>
    </v-dialog>
    <v-dialog v-model="dialogStockPosition" v-if="dialogStockPosition">
      <AddStockPosition
        :Obj="stockPosition"
        :UserId="user.Id"
        @update="update_stock_position"
        @close="dialogStockPosition = false"
      ></AddStockPosition>
    </v-dialog>
    <v-dialog v-if="deleteDialog" v-model="deleteDialog">
      <DeleteConfirmationModal
        :obj="equityHolding ? equityHolding : stockPosition"
        :name="
          equityHolding ? $t('this_equity_holding') : $t('this_stock_position')
        "
        @close="deleteDialog = false"
        @delete="
          (obj) =>
            equityHolding
              ? delete_equity_holding(obj)
              : delete_stock_position(obj)
        "
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationService from "@/services/ValidationService";
import AddStockPosition from "@/views/pages/profile/form/AddStockPositionModal";
import AddEquityHolding from "@/views/pages/profile/form/AddEquityHoldingModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import countriesJson from "@/utils/countries.json";
import moment from "moment";
import { VMoney } from "v-money";
import { UserTypeEnum } from "@/shared/enums/UserType";
import {
  formatToDecimalNumber,
  convertIntNumberInDecimal,
} from "@/shared/helpers/inputHelpers.js";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import DialogAddOrEditPartnerB2b from "@/views/pages/partnerB2b/DialogAddOrEditPartnerB2b";
import { DocumentTypeEnum } from "@/shared/enums/DocumentType.js";
import {
  verifyRequirements,
  checkPasswordMinCharacter,
} from "@/shared/helpers/validatePassword";
import ValidateStrengthPassword from "@/components/dxa/Password/ValidateStrengthPassword";

export default {
  name: "ManageUserBasic",
  components: {
    AddStockPosition,
    AddEquityHolding,
    DeleteConfirmationModal,
    DialogAddOrEditPartnerB2b,
    ValidateStrengthPassword,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    vs: new ValidationService(),
    disabledManagerPartner: false,
    loading: false,
    edit: false,
    rePassword: "",
    selected_nda: null,
    verifyRequirements,
    checkPasswordMinCharacter,
    selected_contract: null,
    show1: false,
    valid: true,
    lazy: false,
    show2: false,
    menuDate: false,
    editPartnerB2bContent: null,
    addPartnerB2bCompanyNameLoading: false,
    disabledPartnerB2bOptions: false,
    bankersFiltered: [],
    dialogEquityHolding: false,
    dialogStockPosition: false,
    stockPosition: null,
    equityHolding: null,
    deleteDialog: false,
    dateIssuer: null,
    menuDateIssuer: false,
    dateLegalRepresentative: null,
    menuDateLegalRepresentative: null,
    portfolioLoading: false,
    portfolioOptions: [],
    portfolioSelected: {},
    asset_options: [],
    bankers: [],
    partnersB2bList: [],
    date: null,
    dialogAddPartnerB2bCompany: false,
    savePartnerCompanyError: false,
    partnerB2bCompanyName: null,
    alpha_register_date: null,
    passwordToValidateStrength: "",
    countries: countriesJson,
    nationalitySelected: null,
    currencyPreference: null,
    managerPartnerList: [],
    CurrencyEnum,
    DocumentTypeEnum,
    envManagerUser: process.env.VUE_APP_MANAGER_USER,
    currencyOptions: [
      {
        text: "R$",
        value: CurrencyEnum.BRL,
      },
      {
        text: "US$",
        value: CurrencyEnum.USD,
      },
    ],
    user: {
      JuridicalType: 0,
      Name: null,
      LastName: null,
      Email: null,
      Type: null,
      AdminType: 0,
      UserPassword: "",
      SignedNda: true,
      SocialNumber: null,
      Nationality: null,
      BankerId: null,
      PartnerId: null,
      PartnerB2bId: null,
      ManagerPartnerId: null,
      Roles: null,
      UseTerms: false,
    },
    disabledUseTerms: false,
    eSimNao: [
      { value: false, text: "Não" },
      { value: true, text: "Sim" },
    ],
    userTypeEnum: UserTypeEnum,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$",
      precision: 2,
      masked: false,
    },
    directives: { money: VMoney },
    profession_options: [],
    profession_text_follback: true,
    JuridicalTypeEnum,
    managerPartnerFallback: true,
    userTypeOptionsToSelect: [],
    userIsManagerAndPartner: false,
    disabledCheckUserIsManagerAndPartner: false,
    userRoles: [],
  }),

  watch: {
    "user.InvestValue"() {
      if (this.user.InvestValue) {
        this.update_portfolio();
      }
    },
    "user.IsAlpha"() {
      if (this.user.IsAlpha) {
        this.initialize_alpha_info();
      }
    },
  },
  computed: {
    alpha_minimum_value() {
      return 200000;
    },
    social_number_valid() {
      return (value) =>
        (value != null &&
          ((value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
            .length == 11 &&
            this.vs.cpf_test(
              value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
            )) ||
            (value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
              .length == 14 &&
              this.vs.cnpj_test(
                value
                  .replaceAll(".", "")
                  .replaceAll("/", "")
                  .replaceAll("-", "")
              )))) ||
        this.$t("format_invalid");
    },
    validDate() {
      return () =>
        (parseInt(this.date.split("/")[2]) > 1600 &&
          this.date &&
          moment([
            parseInt(this.date.split("/")[2]),
            parseInt(this.date.split("/")[1]) - 1,
            parseInt(this.date.split("/")[0]),
          ]).isValid()) ||
        this.$t("valid_date");
    },
    min18Years() {
      return () =>
        this.user.JuridicalType == this.JuridicalTypeEnum.LegalPerson ||
        (this.date != null &&
          moment().diff(
            moment([
              parseInt(this.date.split("/")[2]),
              parseInt(this.date.split("/")[1]) - 1,
              parseInt(this.date.split("/")[0]),
            ]),
            "years"
          ) >= 18) ||
        this.$t("min_18");
    },
    adminTypes() {
      return [{ value: 0, text: this.$t("adminTypeCompliance", 0) }];
    },
    passwordsMatch() {
      return () =>
        this.user.UserPassword === this.rePassword ||
        this.$t("pswds_dnt_match");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    selectRequired() {
      return (value) => value != null || this.$t("required");
    },
    min_valid() {
      return (v) => v.length >= 8 || this.$t("min_char");
    },
    special_valid() {
      return (v) =>
        !/[*|\":<>[\]{}`\\()';&$]/.test(v) || this.$t("special_char");
    },
    email_valid() {
      return (v) =>
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          v
        ) || this.$t("format_invalid");
    },
    accent_valid() {
      return (v) => !/[À-ÖØ-öø-ÿ]/.test(v) || this.$t("accent_char");
    },
    special_required() {
      return (v) => /[*&$!@#?]/.test(v) || this.$t("special_required");
    },
    uppercase_required() {
      return (v) => /[A-Z]/.test(v) || this.$t("uppercase_required");
    },
    number_required() {
      return (v) => /[0-9]/.test(v) || this.$t("number_required");
    },
    lowercase_required() {
      return (v) => /[a-z]/.test(v) || this.$t("lowercase_required");
    },
    passwordMinCharacter() {
      return (v) =>
        checkPasswordMinCharacter(v) || this.$t("password_min_character");
    },
    user_type_options() {
      const userOptions = [
        {
          Name: this.$tc("investor", 1),
          Index: 0,
        },
        {
          Name: this.$tc("admin", 1),
          Index: 1,
        },
        {
          Name: this.$tc("partner", 1),
          Index: 2,
        },
        {
          Name: this.$tc("financial_analyst", 1),
          Index: 4,
        },
        {
          Name: this.$tc("content_analyst", 1),
          Index: 5,
        },
        {
          Name: this.$tc("banker", 1),
          Index: 6,
        },
        {
          Name: this.$tc("alpha_analyst", 1),
          Index: 7,
        },
      ];

      if (process.env.VUE_APP_MANAGER_USER === "TRUE") {
        userOptions.push({
          Name: this.$t("manager"),
          Index: 8,
        });
      }

      return userOptions;
    },
  },
  props: {
    user_prop: Object,
    ReadOnly: Boolean,
    PartnerSelected: String, // Defines if a partner is creating a new banker,
    UserToAddType: Number, // define o tipo de usuário que vai ser adicionado,
    UserToAddInfo: Object,
  },

  async created() {
    this.disabledManagerPartner = false;
    this.alert_msg = null;
    var user_assets = [];
    await this.getPartners();
    await this.get_bankers();

    if (this.PartnerSelected) {
      this.user.Type = this.userTypeEnum.Banker;
      this.user.PartnerId = this.PartnerSelected;
    }

    // dando a liberdade de usar o componente para adicionar qualquer tipo de usuário.
    // lembrando que o campo do tipo de usuário vai ficar desabilitado
    if (this.UserToAddType) {
      this.user.Type = this.UserToAddType;
    }

    // essa prop deve usada, para quando no componente pai, queremos passar alguma prop referente ao tipo de usuário que está usando esse componente e com isso, trabalhar essa prop no componente.
    if (this.UserToAddInfo) {
      // caso o componente pai, passe uma empresa parceira ( um partner está tentando adicionar outro partner )
      // se ele tiver passado uma empresa parceira, populamos com essa opção e desabilitamos o campo.
      if (this.UserToAddInfo.PartnerB2bId) {
        this.user.PartnerB2bId = this.UserToAddInfo.PartnerB2bId;
        this.disabledPartnerB2bOptions = true;
      }
    }

    if (this.user_prop != null) {
      this.edit = true;

      if (this.user_prop.UseTerms) {
        this.disabledUseTerms = true;
      }

      // é um parceiro que é manager e partner
      if (
        this.user_prop.ManagerPartnerId &&
        this.user_prop.PartnerB2bId &&
        this.user_prop.Type === this.userTypeEnum.Partner
      ) {
        this.userIsManagerAndPartner = true;
        this.disabledCheckUserIsManagerAndPartner = true;
      }

      // chamando o get para listar as empresas parceiras
      // esse get só faz no edit, porque no edit temos sempre o id do managerPartner, então já pegamos o managerPartner selecionado e populamos a combo.
      await this.getManagerPartnerSelected(this.user_prop.ManagerPartnerId);

      if (this.user_prop.LegalRepresentative) {
        this.user_prop.LegalRepresentative = {};
      }

      if (this.user_prop.PartnerB2bId) {
        this.disabledPartnerB2bOptions = true;
      }

      this.user = this.user_prop;

      this.checkProfession();
      if (this.user.Type == this.userTypeEnum.Investor) {
        if (this.user.CurrencyPreference) {
          this.currencyPreference = this.user.CurrencyPreference;
        }

        if (this.user.IsAlpha) {
          await this.initialize_alpha_info();
        }

        if (this.user.PartnerUser) {
          this.user.PartnerId = this.user.PartnerUser.PartnerId;
          await this.partnerB2bChanged(true);
        }

        if (this.user.AssetsResources != null) {
          user_assets = this.user.AssetsResources.split(";");
        }

        if (this.user.Birth) {
          var date = this.user.Birth.split("T")[0].split("-");
          this.date = `${date[2]}/${date[1]}/${date[0]}`;
        }

        if (this.user.ExpeditionDate) {
          this.dateIssuer = this.user.ExpeditionDate.split("T")[0];
          if (this.dateIssuer.split("-")[0] == "0001") {
            this.dateIssuer = null;
          }
        }

        if (
          this.user.LegalRepresentative != null &&
          this.user.LegalRepresentative != "" &&
          this.user.LegalRepresentative.ExpeditionDate
        ) {
          this.dateLegalRepresentative =
            this.user.LegalRepresentative.ExpeditionDate.split("T")[0];
          if (this.dateLegalRepresentative.split("-")[0] == "0001") {
            this.dateLegalRepresentative = null;
          }
        } else {
          this.user.LegalRepresentative = {};
        }

        if (this.user.Nationality == null) {
          this.nationalitySelected = this.countries.filter(
            (x) => x.name == "Brazil"
          )[0];
          this.user.Nationality = "Brazil";
        } else {
          this.nationalitySelected = this.countries.filter(
            (x) => x.name == this.user.Nationality
          )[0];
        }

        this.selected_nda = this.user.SignedNda;
        this.selected_contract = this.user.SignedContract;
      }
    } else {
      this.edit = false;
      if (this.user.Type == this.userTypeEnum.Investor) {
        this.selected_nda = true;
        this.selected_contract = true;
        this.user.TotalAssets = 0;
        this.user.MonthlyIncome = 0;
      }
    }

    this.asset_options.push({
      Text: this.$t("movable_property"),
      Marked: user_assets.includes(this.$t("movable_property")),
    });
    this.asset_options.push({
      Text: this.$t("real_estate"),
      Marked: user_assets.includes(this.$t("real_estate")),
    });
    this.asset_options.push({
      Text: this.$t("financial_investments"),
      Marked: user_assets.includes(this.$t("financial_investments")),
    });
    this.asset_options.push({
      Text: this.$t("retirement"),
      Marked: user_assets.includes(this.$t("retirement")),
    });
    this.asset_options.push({
      Text: this.$t("other_income"),
      Marked: user_assets.includes(this.$t("other_income")),
    });

    if (this.user.TotalAssets) {
      this.user.TotalAssets = convertIntNumberInDecimal(this.user.TotalAssets);
    }
  },

  methods: {
    handleChangePassword() {
      this.passwordToValidateStrength = this.user.UserPassword;
    },
    handleCloseDialog() {
      this.dialogAddPartnerB2bCompany = false;
    },
    handleChangeUserType() {
      // forçando o admin marcar se o partner é manager e manager é partner
      this.userIsManagerAndPartner = false;
    },
    async getManagerPartnerSelected(managerPartnerId) {
      if (managerPartnerId) {
        await this.apiService
          .getRequest(`managerpartner/${managerPartnerId}`)
          .then((resp) => {
            let managerPartnerList = [];

            managerPartnerList.push(resp.content);

            if (managerPartnerList.length > 0) {
              this.managerPartnerList = managerPartnerList;
              // desabilitando o campo. Usuário manager, não pode trocar a empresa manager.
              this.disabledManagerPartner = true;
            }
          })
          .catch((err) => {
            this.$toast.error(this.$t("an_error_occoured"));
          });
      }
    },
    async managerPartnerChanged(e) {
      const filterName = e.target.value;

      if (filterName && filterName.length >= 3) {
        await this.getManagerPartnerList(filterName);
      }

      if (filterName && filterName.length < 3) {
        this.managerPartnerFallback = true;
      } else {
        this.managerPartnerFallback = false;
      }
    },
    async getManagerPartnerList(filterName) {
      await this.apiService
        .getRequest(`managerpartner?nameFilter=${filterName}`)
        .then((resp) => {
          this.managerPartnerList = resp.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });
    },
    async handleSavedPartnerB2b() {
      this.dialogAddPartnerB2bCompany = false;
      await this.getPartners();
    },
    async initialize_alpha_info() {
      if (this.user.AlphaRegisterDate) {
        var date = moment(this.user.AlphaRegisterDate);
        this.alpha_register_date = date.format("DD/MM/YYYY");
      } else {
        this.alpha_register_date = moment().format("DD/MM/YYYY");
      }
      if (this.portfolioOptions.length == 0) {
        await this.get_portfolio_options();
      }
      if (!this.user.InvestValue) {
        this.user.InvestValue = this.alpha_minimum_value;
      }
    },
    checkNda() {
      if (this.selected_nda == false || this.selected_nda) {
        return true;
      } else {
        return false;
      }
    },

    checkContract() {
      if (this.selected_contract == false || this.selected_contract) {
        return true;
      } else {
        return false;
      }
    },
    async check_email() {
      if (
        await this.vs.check_if_email_exists(
          this.user.Id,
          this.user.Email,
          false
        )
      ) {
        this.$toast.error(this.$t("user_exists"));
        return false;
      }
      return true;
    },

    async check_social_number() {
      if (
        await this.vs.check_if_social_number_exists(
          this.user.Id,
          this.user.SocialNumber,
          this.user.Type == this.userTypeEnum.Investor ? "user" : "banker"
        )
      ) {
        this.$toast.error(this.$t("social_number_exists"));
        return false;
      }
      return true;
    },

    async get_bankers() {
      await this.apiService
        .getRequest("banker/list")
        .then((resp) => {
          this.bankers = JSON.parse(resp.message);

          // caso tenha um partnerB2bId ele dá opções de bankers que estão associados a esse partner
          if (
            this.user.PartnerB2bId ||
            (this.user_prop && this.user_prop.PartnerB2bId)
          ) {
            const partnerB2bId = this.user.PartnerB2bId
              ? this.user.PartnerB2bId
              : this.user_prop.PartnerB2bId;

            this.bankersFiltered = this.bankers.filter(
              (x) => x.PartnerB2bId == partnerB2bId
            );
          }
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });
    },
    async getPartners() {
      await this.apiService
        .getRequest("partnerb2b")
        .then((resp) => {
          this.partnersB2bList = resp.content;
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });
    },

    partnerB2bChanged(creation) {
      if (creation !== true) {
        this.bankersFiltered = [];
        this.user.BankerId = null;
      }

      if (this.user.PartnerB2bId) {
        this.bankersFiltered = this.bankers.filter(
          (x) => x.PartnerB2bId == this.user.PartnerB2bId
        );
      }
    },

    async update_portfolio() {
      if (this.portfolioLoading || this.portfolioOptions.length == 0) {
        return;
      }
      this.portfolioLoading = true;
      if (this.user.InvestValue < 1000000) {
        this.user.SelectedPortfolioId = this.portfolioOptions[0].id;
      } else if (
        this.user.InvestValue >= 1000000 &&
        this.user.InvestValue < 5000000
      ) {
        this.user.SelectedPortfolioId = this.portfolioOptions[1].id;
      } else if (this.user.InvestValue >= 5000000) {
        this.user.SelectedPortfolioId = this.portfolioOptions[2].id;
      }
      this.portfolioSelected = this.portfolioOptions.filter(
        (x) => x.id == this.user.SelectedPortfolioId
      )[0];
      this.portfolioLoading = false;
    },

    async get_portfolio_options() {
      this.portfolioLoading = true;
      await this.apiService
        .getRequest("alphaportfolio/list-all")
        .then((result) => {
          this.portfolioOptions = result.content;
          if (this.portfolioOptions.length > 0) {
            var index = 1;
            if (this.user.SelectedPortfolioId) {
              index = this.portfolioOptions
                .map((x) => x.id)
                .indexOf(this.user.SelectedPortfolioId);
              if (index < 0) {
                index = 1;
              }
            }
            this.portfolioSelected = this.portfolioOptions[index];
            this.user.SelectedPortfolioId = this.portfolioSelected.id;
          }
        })
        .catch((err) => {});
      this.portfolioLoading = false;
    },

    nationality_code_changed() {
      if (this.nationalitySelected) {
        this.user.Nationality = this.nationalitySelected.name;
      }
    },

    open_delete_dialog_stock_position(obj) {
      this.stockPosition = obj;
      this.equityHolding = null;
      this.deleteDialog = true;
    },

    open_delete_dialog_equity_holding(obj) {
      this.stockPosition = null;
      this.equityHolding = obj;
      this.deleteDialog = true;
    },

    open_stock_position_dialog(obj) {
      this.stockPosition = obj;
      this.dialogStockPosition = true;
    },

    open_equity_holding_dialog(obj) {
      this.equityHolding = obj;
      this.dialogEquityHolding = true;
    },

    open_delete_dialog(obj) {
      this.stockPosition = obj;
      this.dialogStockPosition = true;
    },

    delete_equity_holding(obj) {
      var index = this.user.EquityHoldings.indexOf(obj);
      this.user.EquityHoldings.splice(index, 1);
      this.deleteDialog = false;
    },

    delete_stock_position(obj) {
      var index = this.user.StockPositions.indexOf(obj);
      this.user.StockPositions.splice(index, 1);
      this.deleteDialog = false;
    },

    update_equity_holding(obj) {
      if (this.equityHolding == null) {
        this.user.EquityHoldings.push(obj);
      } else {
        var index = this.user.EquityHoldings.indexOf(this.equityHolding);
        this.user.EquityHoldings[index] = obj;
      }
      this.dialogEquityHolding = false;
    },

    update_stock_position(obj) {
      if (this.stockPosition == null) {
        this.user.StockPositions.push(obj);
      } else {
        var index = this.user.StockPositions.indexOf(this.stockPosition);
        this.user.StockPositions[index] = obj;
      }
      this.dialogStockPosition = false;
    },

    email_error_msg: function () {
      return this.$t("format_invalid");
    },
    async checkFormAddPartnerB2bCompany() {
      if (this.addPartnerB2bCompanyNameLoading) {
        return;
      }
      this.addPartnerB2bCompanyNameLoading = true;
      const result = this.$refs.form.validate();

      if (result) {
        this.submitAddPartnerCompany();
      } else {
        this.addPartnerB2bCompanyNameLoading = false;
      }
    },
    async checkForm() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        if (
          this.user.Type == this.userTypeEnum.Investor &&
          !(await this.check_social_number())
        ) {
          this.loading = false;
          return;
        }
        if (this.user.IsAlpha && this.user.InvestValue < 200000) {
          this.loading = false;
          return;
        }
        if (!(await this.check_email())) {
          this.loading = false;
          return;
        }
        this.submit();
      } else {
        this.loading = false;
      }
    },

    submit: async function () {
      this.error = null;
      var self = this;
      if (this.user.Type != this.userTypeEnum.Admin) {
        this.user.AdminType = 0;
      }
      if (this.user.Type === this.userTypeEnum.Investor) {
        this.user.CurrencyPreference = this.currencyPreference;
        this.user.SignedNda = this.selected_nda;
        this.user.SignedContract = this.selected_contract;
        if (this.edit) {
          if (this.date) {
            var date = this.date.split("/");
            this.user.Birth = `${date[2]}-${date[1]}-${date[0]}T00:00:00`;
            if (this.dateIssuer) {
              this.user.ExpeditionDate =
                this.dateIssuer.toString() + "T00:00:00";
            }
            if (this.dateLegalRepresentative) {
              this.user.LegalRepresentative.ExpeditionDate =
                this.dateLegalRepresentative.toString() + "T00:00:00";
            }
          }
          var stringBuilder = "";
          var assets_selected = this.asset_options.filter(
            (x) => x.Marked == true
          );

          if (assets_selected.length == 0) {
            this.user.AssetsResources = null;
          } else {
            for (var i = 0; i < assets_selected.length; i++) {
              stringBuilder += assets_selected[i].Text;
              if (i != assets_selected.length - 1) {
                stringBuilder += ";";
              }
            }
            this.user.AssetsResources = stringBuilder;
          }
          if (this.user.MonthlyIncome) {
            this.user.MonthlyIncome = parseFloat(this.user.MonthlyIncome);
          }
        }
      } else {
        // se for diferente de investidor o UseTerms por padrão é true ( mas não gera o arquivo para download)
        this.user.UseTerms = true;
      }

      if (this.user.TotalAssets) {
        this.user.TotalAssets = formatToDecimalNumber(this.user.TotalAssets);
      }

      // se o checkbox estiver marcado, então o usuário é manager e partner.
      // se estiver desmarcado ele popula o type normalmente.
      if (this.userIsManagerAndPartner) {
        this.user.Roles = [
          this.userTypeEnum.Partner,
          this.userTypeEnum.ManagerUser,
        ];
      } else {
        this.user.Roles = [this.user.Type];
      }

      // quando ManagerPartnerId for = 0, passamos ele para null.
      if (!this.user.ManagerPartnerId) {
        this.user.ManagerPartnerId = null;
      }

      if (this.edit) {
        this.apiService
          .putRequest("user/edit", this.user)
          .then(function (result) {
            self.$emit("saved", self.user);
            self.$emit("alert", true, self.$t("saved"));
            self.loading = false;
          })
          .catch(function (err) {
            self.$emit("alert", false, err.body.message);
            self.loading = false;
          });
      } else {
        if (this.user.Type == this.userTypeEnum.Investor) {
          this.user.Nationality = this.nationalitySelected.name;
        }

        // ate incluir o campo de nacionalidade e socialNumber na criação de um assessor
        // por padrão nationality = Brazil; RegisterDocumentType = socialNumeber
        if (this.user.Type === this.userTypeEnum.Banker) {
          this.user.Nationality = "Brazil";
          this.user.RegisterDocumentType = this.DocumentTypeEnum.socialNumber;
        }

        // só passa se a senha for válida
        if (this.verifyRequirements(this.user.UserPassword) !== true) {
          this.loading = false;
          return;
        }

        this.apiService
          .postRequest("user/create", this.user, false)
          .then(function (result) {
            self.$router.push("/users");
            self.loading = false;
            self.$toast.success(self.$t("user_created"));
          })
          .catch(function (err) {
            self.$toast.error(self.$t("error_occoured"));
            self.$emit("alert", false, err.body.message);
            self.loading = false;
          });
      }
    },
    async getProfession() {
      const professionInput = document.getElementById("profession_input");
      const professionValue = professionInput.value;
      const professionLength = professionInput.value.length;
      const language = localStorage.getItem("lang");

      if (professionValue.length >= 3) {
        await this.apiService
          .getRequest(`profession/${language}/${professionValue}`)
          .then((result) => {
            this.profession_options = result.content;
          })
          .catch(() => {
            this.$toast.error(this.$t("error_occoured"));
          });
      }

      if (professionLength < 3) {
        this.profession_text_follback = true;
      } else {
        this.profession_text_follback = false;
      }
    },
    filterObject(item, queryText, itemText) {
      let translatedWord = this.$t(item.key);

      return (
        translatedWord
          .toLocaleLowerCase()
          .indexOf(itemText.toLocaleLowerCase()) > -1
      );
    },
    checkProfession() {
      if (this.user.ProfessionId) {
        this.profession_options = [
          { id: this.user.ProfessionId, key: this.user.Profession },
        ];
      }
    },
    translateProfessionOptions(item) {
      return this.$t(item.key);
    },
  },
};
</script>

<style scoped>
.add-company-partner-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-company-partner-button {
  background: #f2f2f2;
  border-radius: 50%;
  padding: 4px;
  width: 42px;
  height: 42px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-company-partner-container-icon {
  color: var(--black);
  font-size: 16px;
}
</style>

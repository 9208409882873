// no export
function formatToCurrency(value) {
  const valueFormatted = value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return valueFormatted;
}

//all export
export const formatToCurrencyInInput = (value) => {
  let formatValueToNumber;
  let valueFormatted;

  // caso ocorra o clique no enter para previvir
  if (isNaN(value)) {
    formatValueToNumber = formatToNumberInInput(value);
  } else {
    formatValueToNumber = Number(value);
  }

  const formatValueToCurrency = formatToCurrency(formatValueToNumber);
  const removePrefix = formatValueToCurrency.replace("R$", "");

  valueFormatted = removePrefix;

  return valueFormatted;
};

export const formatToNumberInInput = (value) => {
  let valueFormatted;

  if (value.includes(",00")) {
    valueFormatted = value.replace(",00", "");
    valueFormatted = Number(valueFormatted.replace(/\D/g, ""));
  } else {
    valueFormatted = Number(value.replace(/\D/g, ""));
  }

  return valueFormatted;
};

export const permissionOnlyNumber = (event) => {
  if (event.keyCode > 47 && event.keyCode < 58) {
    return false;
  } else {
    return true;
  }
};

export const formatToDecimalNumber = (value) => {
  let valueToString = String(value);
  let valueFormatted = valueToString;

  if (valueToString.includes("R$")) {
    valueFormatted = value.replace("R$", "");

    valueFormatted = valueFormatted.replaceAll(".", "");

    valueFormatted = valueFormatted.replace(",", ".");
  } else {
    valueFormatted = Number(value);
  }

  return Number(valueFormatted);
};

export const convertIntNumberInDecimal = (value) => {
  let valueFormatted = value;
  const valueToString = String(value);

  if (!valueToString.includes(".")) {
    valueFormatted = value + ".00";
  }

  return valueFormatted;
};

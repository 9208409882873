<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title class="mb-2">
      <h4 class="dxa_modal_title h4">{{ $t("add_company_partner") }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="checkFormAddPartnerB2bCompany" ref="form">
        <v-text-field
          :rules="[required]"
          :label="$tc('company_partner', 1)"
          class="pt-0"
          v-model="partnerB2bCompanyName"
        />

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            type="submit"
            :loading="loading"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="handleCloseDialog"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-alert v-if="savePartnerCompanyError" class="ma-2" type="error">{{
        $t("an_error_occurred")
      }}</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "DialogAddOrEditPartnerB2b",
  props: {
    partnerB2bContent: String,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    partnerB2bCompanyName: null,
    loading: false,
    savePartnerCompanyError: false,
  }),
  created() {
    if (this.partnerB2bContent) {
      this.partnerB2bCompanyName = this.partnerB2bContent.name;
    }
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    handleCloseDialog() {
      this.$emit("closeDialog");
    },
    async checkFormAddPartnerB2bCompany() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const result = this.$refs.form.validate();

      if (result) {
        this.submitAddPartnerCompany();
      } else {
        this.loading = false;
      }
    },
    async submitAddPartnerCompany() {
      this.loading = true;

      if (this.partnerB2bContent) {
        let payload = { ...this.partnerB2bContent };
        payload.name = this.partnerB2bCompanyName;

        await this.apiService
          .putRequest("partnerB2b", payload)
          .then((resp) => {
            this.$emit("saved");
            this.$toast.success(this.$t("saved"));
          })
          .catch((err) => {
            this.$toast.error(this.$t("an_error_ocurred"));
          });
      } else {
        await this.apiService
          .postRequest("partnerB2b", { name: this.partnerB2bCompanyName })
          .then((resp) => {
            this.$emit("saved", this.partnerB2bCompanyName);
            this.$toast.success(this.$t("saved"));
          })
          .catch((err) => {
            if (err.status === 400) {
              if (err.body.content.name) {
                const partnerB2bExists = err.body.content.name.find(
                  (error) => error === "partnerb2b_exists"
                );

                if (partnerB2bExists) {
                  this.$toast.error(this.$t("company_partner_already_exist"));
                }
              } else {
                this.$toast.error(this.$t("an_error_ocurred"));
              }
            } else {
              this.$toast.error(this.$t("an_error_ocurred"));
            }
          });
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped></style>

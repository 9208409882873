import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"dxa_modal"},[_c(VCardTitle,[_c('h3',{staticClass:"dxa_modal_title h3"},[_vm._v(" "+_vm._s(_vm.$t("manage_stock_position"))+" ")])]),_c(VCardText,[_c(VContainer,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.add_stock_position()}}},[_c(VTextField,{staticClass:"pt-0",attrs:{"color":"primary","label":_vm.$t('name'),"rules":[_vm.required]},model:{value:(_vm.stockPosition.Name),callback:function ($$v) {_vm.$set(_vm.stockPosition, "Name", $$v)},expression:"stockPosition.Name"}}),_c(VTextField,{staticClass:"pt-0",attrs:{"color":"primary","label":_vm.$t('cpf'),"rules":[_vm.required]},model:{value:(_vm.stockPosition.RegistryNumber),callback:function ($$v) {_vm.$set(_vm.stockPosition, "RegistryNumber", $$v)},expression:"stockPosition.RegistryNumber"}}),_c(VTextField,{staticClass:"pt-0",attrs:{"color":"primary","label":_vm.$t('nationality'),"rules":[_vm.required]},model:{value:(_vm.stockPosition.Nationality),callback:function ($$v) {_vm.$set(_vm.stockPosition, "Nationality", $$v)},expression:"stockPosition.Nationality"}}),_c(VTextField,{staticClass:"pt-0",attrs:{"color":"primary","label":_vm.$t('capital'),"rules":[_vm.required],"type":"number","suffix":"%"},on:{"change":function($event){_vm.stockPosition.Capital = parseFloat(_vm.stockPosition.Capital)}},model:{value:(_vm.stockPosition.Capital),callback:function ($$v) {_vm.$set(_vm.stockPosition, "Capital", $$v)},expression:"stockPosition.Capital"}}),_c(VCardActions,{staticClass:"pl-0"},[_c(VSpacer),_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"color":"primary","min-width":"100","type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"red","min-width":"100"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
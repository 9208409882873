<template>
  <v-container v-if="all_loaded" id="users-table" fluid tag="section">
    <base-material-card
      icon="mdi-account"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{
            tabs === tabsEnum.investor ? $tc("user", 2) : "Adicionar parceiro"
          }}</b>
        </h1></template
      >
      <v-btn
        color="primary"
        rounded
        @click="tabs = tabsEnum.partner"
        absolute
        fab
        top
        right
        small
        data-test="InvitedsTable:AddPartnerBtn"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-tabs color="primary" v-model="tabs">
        <v-tab>{{ $tc("investor", 2) }}</v-tab>
        <v-tab>{{ $tc("partner", 2) }}</v-tab>
      </v-tabs>
      <InvestorsTable
        v-if="tabs === tabsEnum.investor"
        :Users="users.map((x) => x.user)"
      />
      <AddPartner v-if="tabs === tabsEnum.partner" />
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import InvestorsTable from "@/views/pages/dashboard/InvestorsTable";
import moment from "moment";
import AddPartner from "../partner/AddPartner";

const tabsEnum = {
  investor: 0,
  partner: 1,
};

export default {
  name: "DashboardInvitedsTable",
  components: {
    InvestorsTable,
    AddPartner,
  },
  data: () => ({
    apiService: new ApiService(),
    all_loaded: false,
    users: [],
    tabsEnum,
    tabs: tabsEnum.investor,
  }),
  async created() {
    let partnerId = JSON.parse(localStorage.getItem("user")).id;

    this.apiService
      .getRequest(`partner/inviteds`)
      .then((resp) => {
        this.users = resp.content;
        this.all_loaded = true;
      })
      .catch((error) => {
        this.all_loaded = true;
      });
  },
  methods: {},
};
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.all_loaded)?_c(VContainer,{attrs:{"id":"users-table","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3 mb-5",attrs:{"icon":"mdi-account","inline":"","color":"secondary"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('h1',{staticClass:"h4"},[_c('b',[_vm._v(_vm._s(_vm.tabs === _vm.tabsEnum.investor ? _vm.$tc("user", 2) : "Adicionar parceiro"))])])]},proxy:true}],null,false,3236502787)},[_c(VBtn,{attrs:{"color":"primary","rounded":"","absolute":"","fab":"","top":"","right":"","small":"","data-test":"InvitedsTable:AddPartnerBtn"},on:{"click":function($event){_vm.tabs = _vm.tabsEnum.partner}}},[_c(VIcon,[_vm._v("mdi-plus")])],1),_c(VTabs,{attrs:{"color":"primary"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c(VTab,[_vm._v(_vm._s(_vm.$tc("investor", 2)))]),_c(VTab,[_vm._v(_vm._s(_vm.$tc("partner", 2)))])],1),(_vm.tabs === _vm.tabsEnum.investor)?_c('InvestorsTable',{attrs:{"Users":_vm.users.map(function (x) { return x.user; })}}):_vm._e(),(_vm.tabs === _vm.tabsEnum.partner)?_c('AddPartner'):_vm._e()],1)],1):_c(VContainer,[_c(VProgressCircular,{staticStyle:{"margin-left":"50%"},attrs:{"indeterminate":"","size":"70","color":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
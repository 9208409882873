import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:_vm.gs.isMobile() ? '' : 'dxa_modal'},[_c(VCardTitle,{staticClass:"mb-2"},[_c('h4',{staticClass:"dxa_modal_title h4"},[_vm._v(_vm._s(_vm.$t("add_company_partner")))])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.checkFormAddPartnerB2bCompany($event)}}},[_c(VTextField,{staticClass:"pt-0",attrs:{"rules":[_vm.required],"label":_vm.$tc('company_partner', 1)},model:{value:(_vm.partnerB2bCompanyName),callback:function ($$v) {_vm.partnerB2bCompanyName=$$v},expression:"partnerB2bCompanyName"}}),_c(VCardActions,{staticClass:"pl-0 dxa_modal_actions"},[_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"color":"primary","min-width":"100","type":"submit","loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"red","min-width":"100"},on:{"click":_vm.handleCloseDialog}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1),(_vm.savePartnerCompanyError)?_c(VAlert,{staticClass:"ma-2",attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.$t("an_error_occurred")))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container v-if="!loading" id="add-User" fluid tag="section">
    <div>
      <ManageUserBasic
        @alert="showAlert"
        @saved="userSaved"
        :UserToAddType="UserTypeEnum.Partner"
        :user_prop="user"
        :UserToAddInfo="partnerInfo"
      ></ManageUserBasic>
      <v-alert
        class="ma-2"
        v-if="alertMsg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alertMsg) }}
      </v-alert>
    </div>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ManageUserBasic from "@/views/pages/user/index/form/tabs/Basic";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "AddPartner",

  components: {
    ManageUserBasic,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    userId: null,
    partnerId: null,
    edit: false,
    user: null,
    success: null,
    alertMsg: null,
    UserTypeEnum,
    partnerInfo: null,
  }),

  computed: {},

  watch: {},

  async created() {
    await this.getPartnerInfo();
  },

  methods: {
    async getPartnerInfo() {
      const userId = JSON.parse(localStorage.getItem("user")).id;

      await this.apiService
        .getRequest(`user/${userId}`)
        .then((resp) => {
          this.partnerInfo = JSON.parse(resp);
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });
    },
    resetAlert() {
      this.success = null;
      this.alertMsg = null;
    },
    showAlert(success, msg) {
      this.success = success;
      this.alertMsg = msg;
    },
    userSaved(user) {
      this.user = user;
    },
  },
};
</script>
